<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div
    fill-height
    class="mt-6 ml-8 mr-6"
  >
    <div>
      <v-icon
        id="iiotDeployDetailsBackButton"
        data-cy="iiotDeployDetailsBackButton"
        large
        class="ml-n5 mb-3"
        @click="goToDeployLogPage()"
      >mdi-chevron-left
      </v-icon>
      <span
        id="iiotDeployDetailsTitle"
        v-if="getCampaign"
        class="deployment-title"
      > {{ $t('deployDetails.detailOfDep') }} {{ getCampaign.operation_name }} </span>
    </div>
    <the-nerve-table
      :id="'iiotDeployDetailsTable'"
      :columns="columns"
      :params="params"
      :empty-state="$t('deployDetails.list.emptyList')"
      :is-action-menu-enabled="false"
      :have-access="canAccess('UI_DEPLOY:LOG_VIEW')"
      :is-row-clickable="false"
      store-module="deploy-details"
      @params-changed="updateParams"
    >
      <template v-slot:additional-actions>
          <v-row v-resize="onResize">
            <v-col
              cols="4"
              lg="2"
              md="2"
              sm="3"
              class="mt-n1 d-flex justify-start align-center"
              :class="{'pb-0': isMarginVisible}"
            >
              <v-icon
                id="iiotDeployDetailsFilter"
                min-width="40px"
              >
                mdi-filter-outline
              </v-icon>
              <v-checkbox
                id="iiotDeployDetailsFinishedCheckbox"
                v-model="params.selectedSuccessful"
                min-width="40px"
                :label="$t('deployDetails.statusMessages.successful')"
                @change="filterBy()"
              />
            </v-col>
            <v-col
              cols="5"
              lg="3"
              md="3"
              sm="4"
              class="mt-n1 d-flex justify-center align-center"
              :class="{'pb-0': isMarginVisible}"
            >
              <v-checkbox
                id="iiotDeployDetailsInProgressCheckbox"
                v-model="params.selectedInProgress"
                min-width="40px"
                :label="$t('deployDetails.statusMessages.inProgress')"
                @change="filterBy()"
              />
            </v-col>
            <v-col
              cols="3"
              lg="2"
              md="2"
              class="mt-n1 d-flex justify-center align-center"
              :class="{'pb-0': isMarginVisible}"
            >
              <v-checkbox
                id="iiotDeployDetailsFailedCheckbox"
                v-model="params.selectedFailed"
                min-width="40px"
                :label="$t('deployDetails.statusMessages.failed')"
                @change="filterBy()"
              />
            </v-col>
            <v-col
              cols="4"
              lg="2"
              md="2"
              sm="3"
              id="iiotNodeUpdateDetailsListCancelledCheckbox"
              :class="{'mt-n1 d-flex justify-end align-center': !isMarginVisible,
              'pt-0 d-flex justify-start align-center': isMarginVisible}"
            >
              <v-checkbox
                id="iiotDeployDetailsCanceledCheckbox"
                v-model="params.selectedCanceled"
                min-width="40px"
                :label="$t('deployDetails.statusMessages.canceled')"
                @change="filterBy()"
              />
            </v-col>
            <v-col
              class="mt-n2 d-flex justify-center align-center"
              :class="{'pt-0': isMarginVisible}"
              lg="1"
              md="1"
              cols="2"
            >
              <deploy-details-table-actions-reset
                :content="content"
              />
            </v-col>
          </v-row>
      </template>
      <template
        v-slot:additional-info
      >
        <div
          class="mt-8"
        >
          <v-row>
            <v-col
              lg="2"
              md="3"
              sm="5"
              xs="5"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    id="iiotDeployDetailsWorkloadName"
                    min-height="100"
                    max-height="100"
                    min-width="150"
                    color="#f0f3f5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-card-title
                      id="iiotDeployDetailsWorkloadNameTitle"
                      class="card-title"
                    >
                      {{ $t('deployDetails.workloadName') }}:
                    </v-card-title>

                    <v-card-text>
                      {{ (MAX_LENGTH_WORKLOAD_DETAIL_FIELD > workloadName.length ) ? workloadName
                       : (workloadName.substr(0, MAX_LENGTH_WORKLOAD_DETAIL_FIELD) + '...') }}
                    </v-card-text>
                  </v-card>
                </template>
                <span class="p0-12 mt-16">{{ workloadName }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              lg="2"
              md="3"
              sm="5"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    id="iiotDeployDetailsWorkloadVersion"
                    min-height="100"
                    max-height="100"
                    min-width="150"
                    color="#f0f3f5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-card-title
                      id="iiotDeployDetailsWorkloadVersionTitle"
                      class="card-title"
                    >
                      {{ $t('deployDetails.workloadVersion') }}:
                    </v-card-title>

                    <v-card-text>
                      {{ (MAX_LENGTH_WORKLOAD_DETAIL_FIELD > workloadVersion.length )
                      ? workloadVersion :
                       (workloadVersion.substr(0, MAX_LENGTH_WORKLOAD_DETAIL_FIELD) + '...')}}
                    </v-card-text>
                  </v-card>
                </template>
                <span class="p0-12 mt-16">{{ workloadVersion }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              lg="2"
              md="3"
              sm="5"
            >
              <v-card
                id="iiotDeployDetailsOperationStartTime"
                min-height="100"
                max-height="100"
                min-width="150"
                color="#f0f3f5"
              >
                <v-card-title
                  id="iiotDeployDetailsOperationStartTimeTitle"
                  class="card-title"
                >
                  {{ $t('deployDetails.operationStartTime') }}:
                </v-card-title>
                <v-card-text
                  v-if="getCampaign"
                >
                  {{ getCampaign.timeOfOperationStart }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              lg="2"
              md="3"
              sm="5"
            >
              <v-card
                id="iiotDeployDetailsOperationFinishTime"
                min-height="100"
                max-height="100"
                min-width="150"
                color="#f0f3f5"
              >
                <v-card-title
                  id="iiotDeployDetailsOperationFinishTimeTitle"
                  class="card-title"
                >
                  {{ $t('deployDetails.operationFinishTime') }}:
                </v-card-title>
                <v-card-text
                  v-if="getCampaign"
                >
                  {{ getCampaign.timeOfOperationFinish }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
           </div>
        <div>
          <v-row>
            <v-col
              lg="2"
              md="3"
              sm="5"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-card
                    id="iiotDeployDetailsReleasedName"
                    min-height="100"
                    max-height="100"
                    min-width="150"
                    color="#f0f3f5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-card-title
                      id="iiotDeployDetailsReleasedNameTitle"
                      class="card-title"
                    >
                      {{ $t('deployDetails.releaseName') }}:
                    </v-card-title>

                    <v-card-text>
                      {{ (MAX_LENGTH_WORKLOAD_DETAIL_FIELD > releaseName.length ) ? releaseName :
                       (releaseName.substr(0, MAX_LENGTH_WORKLOAD_DETAIL_FIELD) + '...') }}
                    </v-card-text>
                  </v-card>
                </template>
                <span class="pt-16 mb-16">{{ releaseName }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              lg="2"
              md="3"
              sm="5"
            >
              <v-card
                id="iiotDeployDetailsType"
                min-height="100"
                max-height="100"
                min-width="150"
                color="#f0f3f5"
              >
                <v-card-title
                  id="iiotDeployDetailsTypeTitle"
                  class="card-title"
                >
                  {{ $t('deployDetails.type') }}:
                </v-card-title>
                <v-card-text
                  v-if="getCampaign"
                >
                  {{ getCampaign.contentSubtype }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              lg="2"
              md="3"
              sm="5"
            >
              <v-card
                id="iiotDeployDetailsStatus"
                min-height="100"
                max-height="100"
                min-width="150"
                color="#f0f3f5"
              >
                <v-card-title
                  id="iiotDeployDetailsStatusTitle"
                  class="card-title"
                >
                  {{ $t('deployDetails.status') }}:
                </v-card-title>
                <v-card-text
                  v-if="getCampaign"
                >
                  {{ getCampaign.status }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              lg="2"
              md="3"
              sm="5"
            >
              <v-card
                id="iiotDeployDetailsProgress"
                min-height="100"
                max-height="100"
                min-width="150"
                color="#f0f3f5"
              >
                <v-card-title
                  id="iiotDeployDetailsProgressTitle"
                  class="card-title"
                >
                  {{ $t('deployDetails.progress') }}:
                </v-card-title>
                <v-card-text
                  v-if="getCampaign.campaignOptions"
                >
                  {{ getCampaign.campaignOptions.progress }} %
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </template>
    </the-nerve-table>
  </div>
</template>

<script>
import { TheNerveTable, ProgressLinearWithStatus } from 'nerve-ui-components';
import { MAX_LENGTH_WORKLOAD_DETAIL_FIELD } from '@/constants';
import DeployDetailsTableActionsReset from '@/components/deploy/deploy-helpers/DeployDetailsTableActionsReset.vue';
import mqtt from '@/plugins/mqtt';

export default {
  components: {
    TheNerveTable,
    DeployDetailsTableActionsReset,
  },
  data() {
    return {
      params: {
        id: '',
      },
      workloadName: '',
      workloadVersion: '',
      releaseName: '',
      MAX_LENGTH_WORKLOAD_DETAIL_FIELD,
      content: {},
      isMarginVisible: false,
    };
  },
  props: {
    page: {
      type: Number,
    },
    itemsPerPage: {
      type: Number,
    },
    search: {
      type: String,
    },
    isFinished: {
      type: String,
    },
    isCanceled: {
      type: String,
    },
    inProgress: {
      type: String,
    },
    isFailed: {
      type: String,
    },
  },
  computed: {
    getCampaign() {
      return this.$store.getters['deploy-details/campaign'];
    },
    columns() {
      return [
        {
          text: this.$t('deployDetails.list.headerSerialNum'),
          value: 'device',
          sortable: false,
        },
        {
          text: this.$t('deployDetails.list.headerAction'),
          value: 'action',
          sortable: false,
        },
        {
          text: this.$t('deployDetails.list.headerProgress'),
          value: 'progress',
          width: '300px',
          sortable: false,
          component: {
            sfc: ProgressLinearWithStatus,
            props: {
              page: 'deployLogDetails',
            },
          },
        },
        {
          text: this.$t('deployDetails.list.headerCounter'),
          value: 'retryCounter',
          sortable: false,
        },
        {
          text: this.$t('deployDetails.list.headerStarted'),
          value: 'started',
          sortable: false,
        },
        {
          text: this.$t('deployDetails.list.headerFinished'),
          value: 'finished',
          sortable: false,
        },
        {
          text: this.canAccess('UI_DEPLOY:LOG_RESET') ? this.$t('defaultTable.action') : '',
          value: '',
          width: '0%',
          sortable: false,
          component: {
            sfc: () => import('@/components/deploy/deploy-helpers/DeployDetailsTableActionsResetAndFeedback.vue'),
            props: {
              iconsAction: ['Retry', 'DisabledRetry', 'MarkTaskAsSuccess', 'DisabledMarkTaskAsSuccess', 'MarkTaskAsFail', 'DisabledMarkTaskAsFail'],
            },
          },
        },
      ];
    },
  },
  async created() {
    this.params = {
      page: this.page || 1,
      itemsPerPage: this.itemsPerPage || 10,
      search: this.search || '',
      selectedSuccessful: this.isFinished !== 'undefined' ? this.isFinished === 'true' : true,
      selectedInProgress: this.inProgress !== 'undefined' ? this.inProgress === 'true' : true,
      selectedFailed: this.isFailed !== 'undefined' ? this.isFailed === 'true' : true,
      selectedCanceled: this.isCanceled !== 'undefined' ? this.isCanceled === 'true' : true,
    };

    await mqtt.subscribeTo('cloud');

    await this.$store.dispatch('deploy-details/get_bulk_operation', window.location.pathname.split('/')[3]);
    await this.$store.dispatch('node-update-log/setType', { type: 'workload' });

    this.content = this.$store.getters['deploy-details/bulkOperation'];
    await this.$store.dispatch('deploy-details/getLog', this.content);

    // eslint-disable-next-line prefer-destructuring
    this.params.id = window.location.pathname.split('/')[3];

    await this.$store.dispatch('deploy-details/fetch', this.params);

    this.content_content = this.$store.getters['deploy-details/content'];
    this.workloadName = this.content_content.content_params[0].params.workloadName;
    this.workloadVersion = this.content_content.content_params[0].params.version.name;
    this.releaseName = this.content_content.content_params[0].params.version.releaseName;
  },
  beforeDestroy() {
    this.$store.dispatch('deploy-details/clear_deploy_details_list_and_count');
    this.$mqtt.unsubscribeFrom('cloud');
  },
  methods: {
    updateParams(parameters) {
      this.params = {
        ...parameters,
        // eslint-disable-next-line no-underscore-dangle
        id: this.content._id,
        selectedSuccessful: this.params.selectedSuccessful,
        selectedInProgress: this.params.selectedInProgress,
        selectedFailed: this.params.selectedFailed,
        selectedCanceled: this.params.selectedCanceled,
      };
      this.$router.push({
        name: 'Deploy Details',
        query: {
          page: parameters.page,
          itemsPerPage: parameters.itemsPerPage,
          search: parameters.search || undefined,
          isFinished: parameters.selectedSuccessful,
          isCanceled: parameters.selectedCanceled,
          inProgress: parameters.selectedInProgress,
          isFailed: parameters.selectedFailed,
        },
      }).catch(() => {});
    },
    async filterBy() {
      this.params.page = 1;
      await this.$store.dispatch('deploy-details/fetch', this.params);
    },
    goToDeployLogPage() {
      this.$store.dispatch('deploy-details/clear_deploy_details_list_and_count');
      this.$router.push({ name: 'Deploy Log List', query: this.$store.getters['labels/getQuery'] });
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 1264;
    },
  },
};
</script>
<style scoped>
  .deployment-title {
    font-size: 24px;
    font-weight: 600;
  }
  .card-title {
    font-size: 14px;
    font-weight: bolder;
  }
</style>
